/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { formatLocalTime, showToast } from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact";
// import { renderHash, renderPrv } from "utils/render";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Confirmed", value: "confirmed" },
  { name: "Blocked", value: "blocked" },
];

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogDeleteMulti = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await UserService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.content) {
        setData(res.content);
        setTotalRecords(res.total);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    setSelectedRows([])
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await UserService.deleteUser({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  // DELETE MULTIPLE
  const handleDeleteMultiple = async () => {
    try {
      const ids = selectedRows.map((obj) => obj._id);
      await UserService.deleteMultiUser({
        body: {
          ids: ids,
        },
      });
      refDialogDeleteMulti.current.close();
      setSelectedRows([])
      showToast(toast, "success", "User Deleted");
      loadData()
    } catch (error) {
      console.log(error)
      refDialogDeleteMulti.current.close();
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => refDialogDeleteMulti.current.show()}
            disabled={!selectedRows || !selectedRows.length || globalFilter.status === 'confirmed'}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Manage Users</h5>
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <Dropdown
          value={globalFilter.status}
          options={STATUS}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="name"
          placeholder="Status"
          showClear
          className={"w-15rem m-1"}
        />
      </div>
    </div>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
            // Select item
            selectionMode={'checkbox'}
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            selectionPageOnly
          >
            <Column selectionMode="multiple" headerStyle={{ width: '4rem', justifyContent: 'center' }} style={{ width: '4rem', justifyContent: 'center' }}></Column>
            <Column
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => String(rowData.refer_code || "").slice(0, 10)}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
            ></Column>

            <Column
              field="full_name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "300px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="phone"
              header="Phone"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="referred_by"
              header="Sponsor"
              body={(rowData) => String(rowData.referred_by || "").slice(0, 10)}
            ></Column>
            {/* <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="address"
              header="Address"
              body={(rowData) => (
                <div className="relative w-full">
                  {renderHash(rowData?.wallet?.address)}
                  <Button
                    icon="bx bxs-copy text-4xl"
                    className="p-button-rounded p-button-success p-button-text absolute top-0 right-0 "
                    aria-label="Copy"
                    onClick={() => {
                      copyText(rowData?.wallet?.address);
                      showToast(toast, "success", "Copied to clipboard!");
                    }}
                  />
                </div>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "250px" }}
              field="prv"
              header="Prv"
              body={(rowData) => (
                <div className="relative w-full">
                  {renderPrv(rowData?.wallet?.privateKey)}
                  <Button
                    icon="bx bxs-copy text-4xl"
                    className="p-button-rounded p-button-success p-button-text absolute top-0 right-0 "
                    aria-label="Copy"
                    onClick={() => {
                      copyText(rowData?.wallet?.privateKey);
                      showToast(toast, "success", "Copied to clipboard!");
                    }}
                  />
                </div>
              )}
            ></Column> */}
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="withdraw_limit"
              header="Withdraw Limit"
              sortable
              body={(rowData) => ((rowData.withdraw_limit || 0) + "%") }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              frozen
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "300px" }}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="User Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
          <VConfirm ref={refDialogDeleteMulti} onConfirm={handleDeleteMultiple} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
