/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import UserService from "services/users";
import { InputNumber } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoadingSaveDetail } = props;

  const emptyData = {
    _id: null,
    user: null,
    user_id: null,
    balances: {
      tsib: 0,
    },
    profit_balances: {
      tsib: 0,
    },
    deposit_balances: {
      tsib: 0,
    },
    salary_and_commission_balances: {
      tsib: 0,
    },
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: (action) => {
      submit(action);
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (val, name, parent) => {
    let _details = { ...details };
    if (parent) {
      _details[`${parent}`] = {
        ..._details[`${parent}`],
        [name]: val,
      };
    } else {
      _details[`${name}`] = val;
    }
    setDetails(_details);
  };

  const submit = async (action) => {
    try {
      setLoadingSaveDetail(true);
      if (data) {
        await UserService.updateFinance({
          params: {
            id: details._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoadingSaveDetail(false);
      showToast(toast, "success", "Save success!");
      onCancel();
      reload();
    } catch (error) {
      setLoadingSaveDetail(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">User Id</label>
        <InputText id="email" value={details.user?.refer_code} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText id="email" value={details.user?.email} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="balances">Old wallet</label>
        <InputNumber
          onValueChange={(e) => onInputChange(e.value, "tsib", "balances")}
          value={details.balances.tsib}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="deposit_balances">Deposit wallet</label>
        <InputNumber
          onValueChange={(e) => onInputChange(e.value, "tsib", "deposit_balances")}
          value={details.deposit_balances.tsib}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="profit_balances">Profit wallet</label>
        <InputNumber
          onValueChange={(e) => onInputChange(e.value, "tsib", "profit_balances")}
          value={details.profit_balances.tsib}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="salary_and_commission_balances">Salary & commission wallet </label>
        <InputNumber
          onValueChange={(e) => onInputChange(e.value, "tsib", "salary_and_commission_balances")}
          value={details.salary_and_commission_balances.tsib}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
